import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/kids-surprise.jpg"
import AppStore from "../../images/appStore.png"
import GooglePlay from "../../images/GooglePlay.jpg"
import Layout from "../../components/layoutSurpriseCustom"
import PageHeaders from "../../components/pageHeaders"
import KidsContent from "../../components/kidsContent"
import SEO from "../../components/seo"

const BadgeImage = styled.img`
  width: 160px;
  margin-top: 2rem;
  margin-right: 1rem;
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled.a`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  &:empty {
    display: none;
  }
  @media (max-width: 640px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    text-align: center;
  }
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Cross Church Kids"
    SecondText="Kids Birth through 6th Grade"
  >
    <SEO title="Cross Church Kids" />
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      What to Expect
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Cross Church Kids is focused on partnering with you to lead kids into a
      growing relationship with Jesus Christ. Our goal is that kids would be
      safe, have fun, and know Jesus. Our incredible, background checked Crew of
      volunteers is here to make that happen! All kids are checked in in the
      Kids building lobby, where our check-in team will give you matching parent
      and child pick up tags. Kids are then dropped off in their classrooms and
      can only be picked up by their parent with the matching tag.
    </ParagraphText>
    <HeaderText>Nursery</HeaderText>
    <ParagraphText>
      Kids 0-2 meet in the nursery located just outside the Worship Center where
      they can play and be taken care of while you worship.
    </ParagraphText>
    <HeaderText>Preschool</HeaderText>
    <ParagraphText>
      Our preschoolers 2 years-Kindergarten meet in classrooms on the East side
      of our Kids building where they have teaching time, music, activities, and
      crafts all geared toward knowing three truths: God made me, God loves me,
      and Jesus wants to be my friend forever.
    </ParagraphText>
    <HeaderText>Elementary</HeaderText>
    <ParagraphText>
      Our 1st-6th graders meet in the main Kids Worship Room for a large group
      teaching time, games, worship, and grade-specific small groups. We want
      kids to learn three main things: I can know God because of Jesus; I can
      trust God no matter what; and I can live for God by loving others.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Special Events and Registrations
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Click <a href="https://linktr.ee/crosschurchkids">HERE</a> to get updated
      information and registration links for camps, classes, events, and more!
    </ParagraphText>
    <a href="/surprise/parents">
      <PageHeaders
        css={css`
          display: flex;
          margin-top: 4rem;
        `}
      >
        Parent Resources
      </PageHeaders>
    </a>
    <HeaderText></HeaderText>
    <ParagraphText
      css={css`
        margin-top: 2rem;
        text-align: center;
      `}
    >
      <a href="https://www.facebook.com/crosschurchkids/">
        <svg
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            height: 44px;
            fill: #333;
          `}
        >
          <path d="m75 512h167v-182h-60v-60h60v-75c0-41.355469 33.644531-75 75-75h75v60h-60c-16.542969 0-30 13.457031-30 30v60h87.292969l-10 60h-77.292969v182h135c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-105v-122h72.707031l20-120h-92.707031v-30h90v-120h-105c-57.898438 0-105 47.101562-105 105v45h-60v120h60v122h-137c-24.8125 0-45-20.1875-45-45zm0 0" />
        </svg>
      </a>
      <a href="https://www.instagram.com/crosschurchkids/">
        <svg
          viewBox="0 0 512 512"
          css={css`
            height: 44px;
            fill: #333;
            margin-left: 2rem;
          `}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m75 512h362c41.355469 0 75-33.644531 75-75v-362c0-41.355469-33.644531-75-75-75h-362c-41.355469 0-75 33.644531-75 75v362c0 41.355469 33.644531 75 75 75zm-45-437c0-24.8125 20.1875-45 45-45h362c24.8125 0 45 20.1875 45 45v362c0 24.8125-20.1875 45-45 45h-362c-24.8125 0-45-20.1875-45-45zm0 0" />
          <path d="m256 391c74.4375 0 135-60.5625 135-135s-60.5625-135-135-135-135 60.5625-135 135 60.5625 135 135 135zm0-240c57.898438 0 105 47.101562 105 105s-47.101562 105-105 105-105-47.101562-105-105 47.101562-105 105-105zm0 0" />
          <path d="m406 151c24.8125 0 45-20.1875 45-45s-20.1875-45-45-45-45 20.1875-45 45 20.1875 45 45 45zm0-60c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15-15-6.730469-15-15 6.730469-15 15-15zm0 0" />
        </svg>
      </a>
    </ParagraphText>
  </Layout>
)

export default IndexPage
